import { snakeifyObject } from 'src/util';

export const RtlFields = {
  rtlPropertiesSoldInTwoYears: 'CX.RTLPROPERTIESSOLD2',
  rtlPropertiesSoldInThreeYears: 'CX.RTLPROPERTIES3',
  foreignNational: 'CX.FOREIGNNATIONAL',
  coborrowerForeignNational: 'CX.COFOREIGNNATIONAL',
  rtlCreditScore: 'CX.RTLCREDITSCORE',
  rtlLiquidAssets: 'CX.RTLASSET1',
  rtlAssetType: 'CX.RTLASSETTYPE1',
};

const additionalCLosingCalendarFields = {
  documentStatusSigning: 'Fields.Log.MS.Status.Docs Signing',
  underWriterContactName: 'Fields.984',
  fileContactsLoanOfficerName: '317',
  loanPurpose: '19', // same as loanType
  transDetailsLoanType: '1172', // same as mortgageType
  qmLoanType: 'Fields.QM.X24',
  fileContactsEscrowCoName: 'Fields.610',
};

export const fieldIds = {
  loanTypeFixAndFlip: 'CX.FIXANDFLIP',
  loanTypeCommercial: 'CX.COMMERCIAL',
  loanChannel: '2626',
  interestMonths: '1177',
  borrowerName: '31',
  borrowerFirstName: '4000',
  borrowerLastName: '4002',
  borrowerFullNameWithSuffix: '1868',
  borrowerEmailAddress: '1240',
  borrowerCurrentResidenceStreetLine1: 'FR0126', // urla2020StreetAddress
  borrowerCurrentResidenceCity: 'FR0106',
  borrowerCurrentResidenceState: 'FR0107',
  borrowerCurrentResidenceZip: 'FR0108',
  borrowerCurrentResidenceUnit: 'FR0127',
  borrowerResidenceStreetLine1: 'BR0126', // urla2020StreetAddress
  borrowerResidenceCity: 'BR0106',
  borrowerResidenceState: 'BR0107',
  borrowerResidenceZip: 'BR0108',
  borrowerResidenceUnit: 'BR0127',
  loanAmount: '1109',
  purchasePrice: '136',
  downPaymentAmount: '1335',
  loanProgram: 'CX.TARGETLOANPROGRAM',
  loanTerm: '4',
  occupancyType: '1811',
  isCondo: 'URLA.X205',
  isCoop: 'URLA.X206',
  hoaMaintenanceFee: '125',
  loanNumber: '364',
  proposedRealEstateTaxesAmount: '1405',
  propertyType: 'URLA.X74',
  applicationStatus: 'Log.MS.Status.Application',
  closedMilestoneStatus: 'Log.MS.Status.Closed',
  approvedMilestoneStatus: 'Log.MS.Status.Approved',
  approvalMilestoneStatus: 'Log.MS.Status.Approval',
  closedMilestoneDate: 'Log.MS.Date.Closed',
  processingMilestoneDate: 'Log.MS.Date.Processing',
  submittalMilestoneDate: 'Log.MS.Date.Submittal',
  approvedMilestoneDate: 'Log.MS.Date.Approved',
  fundingMilestoneDate: 'Log.MS.Date.Funding',
  clearToCloseMilestoneDate: 'Log.MS.Date.Clear To Close',
  loanType: '19',
  mortgageType: '1172',
  newConstructionFlag: 'CX.NEWCONSTRUCTION',
  subjectPropertyAddress: '11',
  subjectPropertyCity: '12',
  subjectPropertyState: '14',
  subjectPropertyZip: '15',
  borrowingEntity: 'CX.LLCNAME',
  urla2020CitizenshipResidencyType: 'URLA.X1',
  guarantyType: 'CX.RTLEXITSTRAT',
  gsePropertyType: '1041',
  subjectNumberOfUnits: '16',
  dayOneLoanAmount: 'CX.RTLDAYONE',
  renovationCost: 'CX.RTLRENOCOST',
  interestReserveAmount: 'CX.RTLRESERVEDOLLAR',
  totalLoanAmount: 'CX.RTLTOTALLOANAMOUNT',
  disbursedHoldbackAmount: 'CX.DISHBAMOUNT',
  holdbackBalance: 'CX.HOLDBACKBALANCE',
  monthlyPaymentAmount: 'CX.MONTHLYPM',
  appraisalContactName: 'CX.APPRAISALENTRYNAME',
  appraisalContactPhone: 'CX.APPRAISALENTRYPHONE',
  appraisalContactBusinessPhone: 'CX.APPRAISALENTRYBUS',
  appraisalContactCellPhone: 'CX.APPRAISALENTRYCELL',
  appraisalContactEmail: 'CX.APPRAISALENTRYEMAIL',
  appraisalContactSpecialInstructions: 'CX.APPRAISALENTRYSPEC',
  appraisalInvestorName: 'CX.APPRAISALTARGETINV',
  appraisalDate: 'CX.APPRAISALINSDATE',
  appraisalSource: 'CX.RTLAPPRAISALSOURCE',
  preAppDate: 'CX.PREAPP.DATE',
  asIsValue: '356',
  rtlAfterRenovationValue: 'CX.RTLVALUEAFTERRENOVATION',
  propertyAcquisitionDate: 'CX.SNAP.RTLPURCHASEDATEWAS',
  purchasePriceFNF: '3038',
  totalProjectCost: 'CX.RTLTOTALPROJCOST',
  LTAIV: 'CX.COM.MAXLTVPCT',
  LTC: 'CX.COM.MAXLTCPCT',
  LTARV: 'CX.COM.MAXLTARV',
  originationDate: '748',
  interestRate: '3',
  ficoScore: 'VASUMM.X23',
  borrowerExperience: 'CX.NUMOPROJECTS',
  accrualType: 'CX.ACCRUALTYPE',
  currentMaturityDate: '78',
  AIV: '356',
  ARV: 'CX.RTLVALUEAFTERRENOVATION',
  totalBudget: 'CX.TOTALBUDGET',
  preRehabSqFt: 'CX.PREREHSQFT',
  postRehabSqFt: 'CX.POSTREHSQFT',
  term: '325',
  firstPaymentDate: '682',
  originalMaturityDate: 'ULDD.X185',
  nextPaymentDueDate: '2397',
  interestAccrualType: 'CX.FNFACCRUALTYPE',
  totalOriginationPoints: 'NEWHUD.X1191',
  cashReserves: 'CX.CASHRESERVES',
  borrowerExperienceNumOfProjects: 'CX.NUMOPROJECTS',
  borrowerExperienceExitValOfProjects: 'CX.EXITVAL',
  crossCollateralizationFlag: 'CX.CROSSCOLATERALFLAG',
  extensionFlag: 'CX.EXTENSIONFLAG',
  costsSpentToDate: 'CX.COSTSPENDINGTD',
  upb: '2',
  numberOfProperties: 'CX.FNFNUMOFPROP',
  disbursedInterestReserve: 'CX.DISBINTRESERVE',
  interestReserveBalance: 'CX.FNFINTERESTRESERVE',
  llcName1: 'CX.LLCNAME',
  llcName2: 'CX.LLCNAME2',
  llcStreet: 'CX.LLCADD1',
  llcCity: 'CX.LLCADD2',
  llcCounty: 'CX.LLCADD3',
  llcState: 'CX.LLCADD4',
  llcZip: 'CX.LLCADD5',
  sbcTitleInsurer: 'CX.SBCTITLEINSURER',
  rtlRenovationStarted: 'CX.RTLRENOVATIONSTARTED',
  rtlChangingPropertyUse: 'CX.RTLPROPERTYUSE',
  rtlPlansApproved: 'CX.RTLPLANSAPPROVED',
  rtlAdditionalSquareFootage: 'CX.RTLADDITIONALSQ',
  rtlGroundUp: 'CX.RTLGROUNDUP',
  rtlValueAsIs: 'CX.RTLVALUEASIS',
  rtlRenoTerm: 'CX.RTLRENOTERM',
  rtlGC: 'CX.RTLGC',
  rtlGCType: 'CX.RTLGCTYPE',
  rtlPropertyOwnership: 'CX.RTLPROPERTYOWNERSHIP',
  rtlOwnershipPercentage: 'CX.RTLOWNERSHIPPERCENT',
  refinancePropertyAcquiredYear: '24',
  milestoneLockDays: 'MS.LOCKDAYS',
  totalProjectCosts: 'CX.RTLTOTALPROJCOST',
  appraisalOrderedDate: 'CX.APPRAISALORDERED',
  qcManualSubmit: 'CX.QCMANUALSUBMIT',
  completedLoanForms: 'CX.COMPLETEDLOANFORMS',
  creditAuthConfirmationPrefix: 'CX.CREDITAUTHCONF',
  commercialTermsheetSubmitted: 'CX.COMMERCIALSUBMITTED',
  commercialTermsheetIssued: 'CX.COMMERCIALTERMISSUE',
  commercialTermsheetSigned: 'CX.COMMERCIALTERMSIGNED',
  commercialDisclosureStatusPrefix: 'CX.COMMERCIALDISCLOSURE',
  disclosureStatus: 'CX.CINCH.DISCLOSURESTATUS',
  commercialFifteenUnit: 'CX.FIFTEENUNIT',
  riFifteenUnit: 'CX.RI.FIFTEENUNIT',
  nqmLeStatus: 'CX.NQMLESTATUS',
  sendDisclosures: 'CX.SENDDISCLOSURES',
  loanOfficerAssociateId: 'LoanTeamMember.UserID.Loan Officer',
  loanProcessorAssociateId: 'LoanTeamMember.UserID.Loan Processor',
  fileStarterAssociateId: 'LoanTeamMember.UserID.File Starter',
  assistantLOAssociateId: 'LoanTeamMember.UserID.Assistant LO',
  loanOfficerAssociateName: 'LoanTeamMember.Name.Loan Officer',
  customLSSUB1A: 'CX.LS.SUB1A',
  customLSSUB1B: 'CX.LS.SUB1B',
  customLSSUB1C: 'CX.LS.SUB1C',
  customLSSUB1E: 'CX.LS.SUB1E',
  customLSSUB1F: 'CX.LS.SUB1F',
  customLSSUB1G: 'CX.LS.SUB1G',
  rateLockExpirationDate: '762',
  amortizationType: '608',
  loanProcessorName: '362',
  initialDisclosureFileDate: '3152',
  freddieMacLTV: '353',
  applicationDate: '745',
  targetInvestor: 'CX.TARGETINVESTOR',
  loanInvestorName: 'VEND.X263',
  commercialBrokerUserEmail: 'CX.COMBROKERUSEREMAIL',
  borrowerHomePhone: '66',
  CUST14FV: 'CUST14FV',
  processingComments: 'CX.PROCESSINGCOMMENTS',
  pruvconv: 'CX.PRUWCONV',
  noleDisclosuresCompleted: 'CX.NOLELOANDISCCOMPLETE',
  gfeApplicationDate: '3142',
  noticeDate: 'CX.NOTICEDATE',
  denialRequested: 'CX.DENIALREQUESTED',
  sixtyDays: 'CX.60DAYS',
  hmdaCurrentLoanStatus: '1393',
  isDuplicating: 'CX.ISDUPLICATING',
  ...RtlFields,
  ...additionalCLosingCalendarFields,
};


export const canonicalFieldIds = {
  get: (fieldId) => `Fields.${fieldId}`,
  lockAndRequestStatus: 'Loan.LockAndRequestStatus',
  borrowerName: 'Loan.BorrowerName',
  loanFolder: 'Loan.LoanFolder',
  loanNumber: 'Loan.LoanNumber',
  loanSource: 'Loan.LoanSource',
  currentMilestoneName: 'Loan.CurrentMilestoneName',
  nextMilestoneName: 'NextMilestone.MilestoneName',
  nextMilestoneDate: 'Loan.NextMilestoneDate',
  loanLastModified: 'Loan.LastModified',
};

export const GenericFields = {};

export const snakeFieldIds = snakeifyObject(fieldIds, {
  keys: false,
  values: true,
});

export const customFieldDict2Array = (customFieldsData) => {
  const res = [];
  for (const key in customFieldsData) {
    if (!fieldIds[key]) {
      throw new Error(`Could not find fieldIds['${key}']. Did it change?`);
    }
    res.push({
      fieldName: fieldIds[key],
      value: customFieldsData[key],
    });
  }
  return res;
};

/**
 *
 * @param {number} limit
 * @param {'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H'} suffix
 */
export const customLSSubSequenceGenerator = (limit, suffix) => {
  return new Array(limit)
    .fill(0)
    .map((_, i) => `CX.LS.SUB${i + 1}${suffix}`.toUpperCase());
};

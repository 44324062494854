import { useMemo } from 'react';
import {
  useLoan,
  useLoanCustomFieldsMapped,
  useLoanTypeFlags,
} from 'src/hooks';
import {
  EncompassLoan,
  FeeType,
  Gfe2010FeeParentType,
  Gfe2010FeeType,
  LoanPurposeType,
  EncompassCustomFieldsMapped,
} from 'src/types';
import { useParams } from 'react-router-dom';
import { useLoanApplicationIndex } from '../use-loan-application';
import { RequiredItemLocation } from 'src/types/FormValidation';

const emptyArray = [];

export const useTermsheetRequirements = (
  loanGuid: string,
  rtlData: { propertyAddress: string; propertyType: string }[] = emptyArray,
) => {
  const loan = useLoan(loanGuid);
  const fields = useLoanCustomFieldsMapped(loanGuid);
  const loanFlags = useLoanTypeFlags(loanGuid);

  const useNewCommercial = useMemo(
    () =>
      new Date(loan?.loanCreationDate ?? '1970-01-01') >=
      new Date('2024-12-01'),
    [loan?.loanCreationDate],
  );

  const { applicationId } = useParams();
  const appIndex = Math.max(
    useLoanApplicationIndex(loan?.id || '', applicationId),
    0,
  );

  return useMemo(() => {
    if (!loan) return [];

    const items = [
      {
        section: '1003',
        missing: get1003Requirements(loan, fields, useNewCommercial),
      },
    ];

    if (!loanFlags.isFnf && !loanFlags.isResidential && !useNewCommercial) {
      items.push({
        section: 'Title fees',
        missing: getTitleFeesRequirements(loan, fields),
      });
    }

    if (loanFlags.isFnf) {
      items.push({
        section: 'RTL',
        missing: getRTLRequirements(loan, fields, rtlData, appIndex),
      });
    }

    return items;
  }, [
    loan,
    fields,
    loanFlags.isFnf,
    loanFlags.isResidential,
    useNewCommercial,
    rtlData,
    appIndex,
  ]);
};

const get1003Requirements = (
  loan: EncompassLoan,
  fields: EncompassCustomFieldsMapped | null,
  useNewCommercial: boolean,
): RequiredItemLocation[] => {
  const formBasedOnLoanType =
    fields?.legacyLoanTypes?.fixAndFlip === 'Yes' ? 'prequal-fnf' : 'loan-info';
  const values: RequiredItemLocation[] = [
    {
      name: 'Purpose of Loan',
      value: loan.property?.loanPurposeType,
      location: `/loans/${loan.id}/forms/${formBasedOnLoanType}`,
      inputName: 'property.loanPurposeType',
    },
    {
      name: 'Borrower First Name',
      value: loan.applications?.[0]?.borrower?.firstName,
      location: `/loans/${loan.id}/forms/borrower-info`,
      inputName: 'borrower.firstName',
    },
    {
      name: 'Borrower Last Name',
      value: loan.applications?.[0]?.borrower?.lastName,
      location: `/loans/${loan.id}/forms/borrower-info`,
      inputName: 'borrower.lastName',
    },
    {
      name: 'Borrower Email Address',
      value: loan.applications?.[0]?.borrower?.emailAddressText,
      location: `/loans/${loan.id}/forms/borrower-info`,
      inputName: 'borrower.emailAddressText',
    },
    {
      name: 'Borrower SSN',
      value: loan.applications?.[0]?.borrower?.taxIdentificationIdentifier,
      location: `/loans/${loan.id}/forms/borrower-info`,
      inputName: 'borrower.taxIdentificationIdentifier',
    },
  ];
  if (!useNewCommercial) {
    values.push({
      name: 'Title Will Be Held by Name',
      value: fields?.llc?.name,
      location: `/loans/${loan.id}/forms/loan-info`,
      inputName: 'customFieldsMapped.llc.name',
    });
  }
  if (fields?.loanPurpose?.preapproval !== 'true') {
    values.push({
      name: 'Subject Property Address',
      value: loan.property?.addressLineText,
      location: `/loans/${loan.id}/forms/${formBasedOnLoanType}`,
      inputName: 'property.addressLineText',
    });
  }

  if (
    loan.property?.loanPurposeType &&
    [
      LoanPurposeType.CashOutRefinance,
      LoanPurposeType.NoCashOutRefinance,
    ].includes(loan.property?.loanPurposeType) &&
    fields?.legacyLoanTypes?.fixAndFlip !== 'Yes'
  ) {
    if (
      loan.propertyAppraisedValueAmount === undefined &&
      loan.propertyEstimatedValueAmount === undefined
    ) {
      values.push({
        name: 'Appraised or Estimated Amount',
        value: undefined,
        location: `/loans/${loan.id}/forms/loan-info`,
        inputName: 'propertyAppraisedValueAmount',
      });
    }
  }
  return values.filter((x) => !x.value);
};

const getTitleFeesRequirements = (
  loan: EncompassLoan,
  fields: EncompassCustomFieldsMapped | null,
): RequiredItemLocation[] => {
  const fees = loan.fees;
  const gfeFees = loan.closingCost?.gfe2010?.gfe2010Fees;

  const values: RequiredItemLocation[] = [
    {
      name: 'Application Fee',
      value: fees?.find((x) => x.feeType === FeeType.MortgageInspectionFee)
        ?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'applicationFee',
    },
    {
      name: 'Processing Fee',
      value: fees?.find((x) => x.feeType === FeeType.ProcessingFee)
        ?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'processingFee',
    },
    {
      name: 'Underwriting Fee',
      value: fees?.find((x) => x.feeType === FeeType.UnderwritingFee)
        ?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'underwritingFee',
    },
    {
      name: 'Percentage of Loan Amount',
      value: gfeFees?.find(
        (x) =>
          x.gfe2010FeeParentType ===
            Gfe2010FeeParentType.Section800LOCompensation &&
          x.gfe2010FeeType === Gfe2010FeeType.DiscountPoint &&
          x.gfe2010FeeIndex === 1,
      )?.rate,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'closingCost.gfe2010Fees.discountPointPercent',
    },
    {
      name: 'Fixed Dollar Amount',
      value: gfeFees?.find(
        (x) =>
          x.gfe2010FeeParentType ===
            Gfe2010FeeParentType.Section800LOCompensation &&
          x.gfe2010FeeType === Gfe2010FeeType.DiscountPoint &&
          x.gfe2010FeeIndex === 1,
      )?.additionalAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'closingCost.gfe2010Fees.discountPointAdditionalAmount',
    },
    {
      name: 'Appraisal Fee',
      value: fees?.find((x) => x.feeType === FeeType.AppraisalFee)
        ?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'fees.appraisalFee',
    },
    {
      name: 'Credit Report Fee',
      value: fees?.find((x) => x.feeType === FeeType.CreditReportFee)
        ?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'fees.creditReportFee',
    },
    {
      name: 'Attorney Name',
      value: gfeFees?.find(
        (x) =>
          x.gfe2010FeeParentType === Gfe2010FeeParentType.Section800 &&
          x.gfe2010FeeType === Gfe2010FeeType.Line816,
      )?.paidToName,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'gfeFees.lenderAttourneyName',
    },
    {
      name: 'Attorney Fee',
      value: fees?.find((x) => x.feeType === FeeType.UserDefinedFee816)
        ?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'fees.lenderAttourneyAmount',
    },
    {
      name: 'Title Service Company Name',
      value: gfeFees?.find(
        (x) =>
          x.gfe2010FeeParentType === Gfe2010FeeParentType.Section1100 &&
          x.gfe2010FeeType === Gfe2010FeeType.Line1101a,
      )?.paidToName,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'gfeFees.titleFeeName',
    },
    {
      name: 'Title Service Fee',
      value: gfeFees?.find(
        (x) =>
          x.gfe2010FeeParentType === Gfe2010FeeParentType.Section1100 &&
          x.gfe2010FeeType === Gfe2010FeeType.Line1101a,
      )?.borPaidAmount,
      location: `/loans/${loan.id}/forms/loan-terms`,
      inputName: 'gfeFees.titleFeeAmount',
    },
    {
      name: 'Closing Date',
      value: loan.fhaVaLoan?.closingDate,
      location: `/loans/${loan.id}/services/closing`,
      inputName: 'closingDate',
    },
  ];

  return values.filter((x) => !x.value);
};

const getRTLRequirements = (
  loan: EncompassLoan,
  fields: EncompassCustomFieldsMapped | null,
  rtlData: { propertyAddress: string; propertyType: string }[],
  appIndex: number,
): RequiredItemLocation[] => {
  const values: RequiredItemLocation[] = [
    {
      name: 'Properties Sold (2 Years)',
      value: fields?.rtl?.propertiesSoldInTwoYears,
      location: `/loans/${loan.id}/forms/borrower-experience`,
      inputName: 'customFieldsMapped.rtl.propertiesSoldInTwoYears',
    },
    {
      name: 'Properties Sold (3 Years)',
      value: fields?.rtl?.propertiesSoldInThreeYears,
      location: `/loans/${loan.id}/forms/borrower-experience`,
      inputName: 'customFieldsMapped.rtl.propertiesSoldInThreeYears',
    },
    // {
    //   name: 'Liquid Assets',
    //   value: rtlData[0]?.propertyAddress,
    //   location: `/loans/${loan.id}/forms/borrower-experience`,
    //   inputName: `${appIndex}.propertyAddress`,
    // },
    // {
    //   name: 'Liquid Assets Type',
    //   value: rtlData[0]?.propertyType,
    //   location: `/loans/${loan.id}/forms/borrower-experience`,
    //   inputName: `${appIndex}.propertyType`,
    // },
    {
      name: 'Credit Score',
      value: fields?.rtl?.creditScore,
      location: `/loans/${loan.id}/forms/borrower-experience`,
      inputName: 'customFieldsMapped.rtl.creditScore',
    },
    {
      name: 'Loan Amount',
      value: loan.borrowerRequestedLoanAmount,
      location: `/loans/${loan.id}/forms/loan-info`,
      inputName: 'borrowerRequestedLoanAmount',
    },
    {
      name: 'Renovation Cost',
      value: fields?.rtl?.renovationCost,
      location: `/loans/${loan.id}/forms/prequal-fnf`,
      inputName: 'customFieldsMapped.rtl.renovationCost',
    },
  ];

  if (fields?.rtl?.renovationStarted === 'Yes') {
    values.push(
      {
        name: 'As-is Value',
        value: fields?.rtl?.valueAsIs,
        location: `/loans/${loan.id}/forms/prequal-fnf`,
        inputName: 'customFieldsMapped.rtl.valueAsIs',
      },
      {
        name: 'After Repair Value',
        value: fields?.rtl?.afterRenovationValue,
        location: `/loans/${loan.id}/forms/prequal-fnf`,
        inputName: 'customFieldsMapped.rtl.afterRenovationValue',
      },
      {
        name: 'Renovation Term',
        value: fields?.rtl?.renovationTerm,
        location: `/loans/${loan.id}/forms/prequal-fnf`,
        inputName: 'customFieldsMapped.rtl.renovationTerm',
      },
      {
        name: 'Exit Status',
        value: fields?.rtl?.exitStrategy,
        location: `/loans/${loan.id}/forms/prequal-fnf`,
        inputName: 'customFieldsMapped.rtl.exitStrategy',
      },
    );

    if (fields?.rtl?.gc === 'Yes' && !fields?.rtl?.gcType) {
      values.push({
        name: 'GC Type',
        value: undefined,
        location: `/loans/${loan.id}/forms/prequal-fnf`,
        inputName: 'customFieldsMapped.rtl.gcType',
      });
    }

    if (
      fields?.rtl?.propertyOwnership === 'No' &&
      !fields?.rtl?.ownershipPercent
    ) {
      values.push({
        name: 'Ownership Percent',
        value: undefined,
        location: `/loans/${loan.id}/forms/prequal-fnf`,
        inputName: 'customFieldsMapped.rtl.ownershipPercent',
      });
    }
  }

  return values.filter((x) => !x.value && x.value !== 0);
};

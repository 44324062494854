import { apiAxios } from 'src/util';

export const getDrawRequests = async (loanGuid) => {
  const drawRequests = await apiAxios.get(`draw-request/${loanGuid}`);
  return drawRequests.data;
};

export const submitDrawRequest = async (loanGuid, drawRequest) => {
  return await apiAxios.post(`draw-request/${loanGuid}`, drawRequest);
};

export const patchDrawRequest = async (id, patch) => {
  return await apiAxios.patch(`draw-request/${id}`, patch);
};

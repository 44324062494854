import { apiAxios } from 'src/util';
import { BorrowerType, ConsentType } from 'src/types';

export const getConsentStatus = async (
  loanGuid,
  applicationType?: BorrowerType,
  consentType?: ConsentType,
) => {
  const res = await apiAxios.get(
    `/consent/loans/${loanGuid}?applicantType=${
      applicationType ?? ''
    }&consentType=${consentType ?? ''}`,
  );
  return res;
};

export const retryConsentLoanPush = async (loanGuid, consentType) => {
  await apiAxios.post(
    `/consent/loans/${loanGuid}/retry?consentType=${consentType}`,
  );
};

export const requestConsent = async (
  loanGuid,
  applicationGuid,
  applicationType: BorrowerType,
  consentType: ConsentType,
) => {
  const res = await apiAxios.post(
    `/consent/loans/${loanGuid}/applications/${applicationGuid}/${applicationType}/request?consentType=${consentType}&sourceSystem=CinchClient`,
  );
  return res;
};

import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useNavItems } from 'src/components/baseComponents/Header/Tabs';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import styles from './index.module.scss';
import { AvatarContainer } from 'src/components/baseComponents/Header/UserMenu/AvatarContainer';
import { useCurrentLoanOfficer } from 'src/hooks';
import { useUserMenuItemGroups } from 'src/components/baseComponents/Header/UserMenu';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { mdiPlus, mdiHome, mdiOfficeBuilding } from '@mdi/js';
import HasRole from '../../HasRole';
import { ROLE_NAMES } from 'src/util';

const MobileNavDrawer = ({ open, onClose }) => {
  const user = useCurrentLoanOfficer();
  const navItems = useNavItems();
  const userMenuItemGroups = useUserMenuItemGroups();
  const newLoanPageRouteMatch = useRouteMatch('/loans/new');
  const filteredUserMenuItemGroups = useMemo(() => {
    return userMenuItemGroups
      .map((g) => g.filter((x) => !navItems.some((nav) => nav.link === x.to)))
      .filter((g) => g.length);
  }, [navItems, userMenuItemGroups]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      className={styles.mobileNavDrawer}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className={clsx(styles.content, 'flex-container flex-vertical')}>
        <header className={styles.header}>
          <AvatarContainer
            user={user}
            className={styles.avatarContainer}
            size={40}
          />
        </header>
        <Divider />
        <div className={clsx(styles.scroller, 'flex')}>
          <div className={styles.newLoanContainer}>
            <HasRole roles={[ROLE_NAMES.FMM]}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                startIcon={
                  <>
                    <Icon path={mdiPlus} />
                    <Icon path={mdiHome} />
                  </>
                }
                component={Link}
                to='/loans/new'
                className={styles.newLoanButton}
                disabled={Boolean(newLoanPageRouteMatch)}
                onClick={() => onClose()}
              >
                NEW RESIDENTIAL
              </Button>
            </HasRole>
          </div>
          <div className={styles.newLoanContainer}>
            <HasRole roles={[ROLE_NAMES.FMCAPITAL, ROLE_NAMES.ADMINISTRATOR]}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                startIcon={
                  <>
                    <Icon path={mdiPlus} />
                    <Icon path={mdiOfficeBuilding} />
                  </>
                }
                component={Link}
                to='/loans/new?type=commercial'
                className={styles.newLoanButton}
                disabled={Boolean(newLoanPageRouteMatch)}
                onClick={() => onClose()}
              >
                NEW COMMERCIAL
              </Button>
            </HasRole>
          </div>
          <List style={{ paddingTop: 0 }}>
            {navItems.map(({ text, link, icon, exact }) => {
              return (
                <ListItem
                  key={link}
                  component={NavLink}
                  to={link}
                  exact={exact}
                  button
                  className={styles.navButton}
                  activeClassName={styles.navButtonActive}
                  onClick={() => onClose()}
                >
                  <ListItemIcon>
                    <Icon path={icon} />
                  </ListItemIcon>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <List>
            {filteredUserMenuItemGroups.map((group) => {
              const items = group.map(
                ({ name, to, icon, exact, red, forAdmin }) => (
                  <ListItem
                    key={to}
                    component={NavLink}
                    exact={exact}
                    to={to}
                    button
                    activeClassName={styles.navButtonActive}
                    onClick={() => onClose()}
                    className={clsx(styles.navButton, {
                      [styles.adminListItem]: forAdmin,
                      [styles.redListItem]: red,
                    })}
                  >
                    <ListItemIcon>
                      <Icon path={icon} />
                    </ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                  </ListItem>
                ),
              );
              //if (i < a.length - 1) {
              //  items.push(
              //    <Divider className={styles.divider} key={`divider-${i}`} />,
              //  );
              //}
              return items;
            })}
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileNavDrawer;

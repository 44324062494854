import { apiAxios } from 'src/util';

export const QueryGuidelines = async (sessionId, question) => {
  let searchUrl = `guideline/query?question=${question}`;
  if (sessionId) {
    searchUrl += `&sessionId=${sessionId}`;
  }
  const res = await apiAxios.post(searchUrl);
  return res.data;
};

import { apiAxios } from 'src/util';

export const getAllLoanOfficers = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/all');
  return res.data;
};
export const getLoanOfficersList = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/list');
  return res.data;
};
export const getLoanOfficer = async (id) => {
  const res = await apiAxios.get('/accounting/loanofficer', {
    params: {
      id: id,
    },
  });
  return res.data;
};

export const updateLoanOfficer = async (loanOfficer) => {
  const res = await apiAxios.patch('/accounting/loanofficer', loanOfficer);
  return res.data;
};
export const deleteLoanOfficer = async (loanOfficer) => {
  const res = await apiAxios.delete('/accounting/loanofficer', {
    data: loanOfficer,
  });
  return res.data;
};

export const getAllLoanOfficersAltnames = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/alt-name');
  return res.data;
};
export const updateLoanOfficerAltName = async (loanOfficerAltName) => {
  const res = await apiAxios.patch(
    '/accounting/loanofficer/alt-name',
    loanOfficerAltName,
  );
  return res.data;
};
export const deleteLoanOfficerAltName = async (loanOfficerAltName) => {
  const res = await apiAxios.delete('/accounting/loanofficer/alt-name', {
    data: loanOfficerAltName,
  });
  return res.data;
};

export const getAllLoanOfficersBPSs = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/bps');
  return res.data;
};
export const updateLoanOfficerBPS = async (item) => {
  const res = await apiAxios.patch('/accounting/loanofficer/bps', item);
  return res.data;
};
export const deleteLoanOfficerBPS = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/bps', {
    data: item,
  });
  return res.data;
};

export const getAllLoanOfficersMarkups = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/markup');
  return res.data;
};
export const updateLoanOfficerMarkup = async (item) => {
  const res = await apiAxios.patch('/accounting/loanofficer/markup', item);
  return res.data;
};
export const deleteLoanOfficerMarkup = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/markup', {
    data: item,
  });
  return res.data;
};

export const getAllLoanOfficersBudgetPCTs = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/budget-percent');
  return res.data;
};
export const updateLoanOfficerBudgetPCT = async (item) => {
  const res = await apiAxios.patch(
    '/accounting/loanofficer/budget-percent',
    item,
  );
  return res.data;
};
export const deleteLoanOfficerBudgetPCT = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/budget-percent', {
    data: item,
  });
  return res.data;
};

export const getAllLoanOfficersBudgetOvrs = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/ovr');
  return res.data;
};
export const updateLoanOfficerBudgetOvr = async (item) => {
  const res = await apiAxios.patch('/accounting/loanofficer/ovr', item);
  return res.data;
};
export const deleteLoanOfficerBudgetOvr = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/ovr', {
    data: item,
  });
  return res.data;
};

export const getAllLoanOfficersBudget = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/budget');
  return res.data;
};
export const updateLoanOfficerBudget = async (item) => {
  const res = await apiAxios.patch('/accounting/loanofficer/budget', item);
  return res.data;
};
export const deleteLoanOfficerBudget = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/budget', {
    data: item,
  });
  return res.data;
};

export const getAllLoanOfficersAdjustment = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/adjustments');
  return res.data;
};
export const updateLoanOfficerAdjustment = async (item) => {
  const res = await apiAxios.patch('/accounting/loanofficer/adjustments', item);
  return res.data;
};
export const deleteLoanOfficerAdjustment = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/adjustments', {
    data: item,
  });
  return res.data;
};

export const getAccountingBranches = async () => {
  const res = await apiAxios.get('/accounting/loanofficer/branch');
  return res.data;
};
export const updateAccountingBranches = async (item) => {
  const res = await apiAxios.patch('/accounting/loanofficer/branch', item);
  return res.data;
};
export const deleteAccountingBranches = async (item) => {
  const res = await apiAxios.delete('/accounting/loanofficer/branch', {
    data: item,
  });
  return res.data;
};
export const getAutoEmailStatus = async (loId) => {
  const res = await apiAxios.get(
    '/accounting/loanofficer/auto-email-status?loId=' + loId,
  );
  return res.data;
};
export const updateAutoEmailStatus = async (loId, status) => {
  const res = await apiAxios.patch(
    '/accounting/loanofficer/auto-email-status?loId=' +
      loId +
      '&status=' +
      status,
  );
  return res.data;
};

export const getAccountingAdpCodes = async () => {
  const res = await apiAxios.get('/accounting/adp-codes');
  return res.data;
};
export const updateAccountingAdpCodes = async (item) => {
  const res = await apiAxios.patch('/accounting/adp-codes', item);
  return res.data;
};
export const deleteAccountingAdpCodes = async (item) => {
  const res = await apiAxios.delete('/accounting/adp-codes', { data: item });
  return res.data;
};

export const getAdjustmentCategories = async () => {
  const res = await apiAxios.get('/accounting/adjustment-categories');
  return res.data;
};
export const updateAdjustmentCategories = async (item) => {
  const res = await apiAxios.patch('/accounting/adjustment-categories', item);
  return res.data;
};
export const insertAdjustmentCategories = async (item) => {
  const res = await apiAxios.post('/accounting/adjustment-categories', item);
  return res.data;
};
export const deleteAdjustmentCategories = async (item) => {
  const res = await apiAxios.delete('/accounting/adjustment-categories', {
    data: item,
  });
  return res.data;
};

export const getBudgetCategories = async () => {
  const res = await apiAxios.get('/accounting/budget-categories');
  return res.data;
};
export const addBudgetCategories = async (item) => {
  const res = await apiAxios.post('/accounting/budget-categories', item);
  return res.data;
};
export const deleteBudgetCategories = async (item) => {
  const res = await apiAxios.delete('/accounting/budget-categories', {
    data: item,
  });
  return res.data;
};

export const addBudgetAndAdjustments = async () => {
  const res = await apiAxios.post('/accounting/payroll/budgets-adjustments');
  return res.data;
};

export const initializePayroll = async (date1, date2, date3) => {
  const res = await apiAxios.post(
    '/accounting/payroll/initialize-payroll/' +
      date1.toString() +
      '/' +
      date2.toString() +
      '/' +
      date3.toString(),
  );
  return res.data;
};

export const finalizePayroll = async () => {
  const res = await apiAxios.post('/accounting/payroll/finalize-payroll');
  return res.data;
};

export const getCommissionLoansList = async (data) => {
  const res = await apiAxios.post('/accounting/payroll/loan-list', data);
  return res.data;
};
export const getCommissionLoan = async (loanNumber) => {
  const res = await apiAxios.get('/accounting/payroll', {
    params: {
      loanNumber: loanNumber,
    },
  });
  return res.data;
};
export const getNextCommissionLoan = async (request) => {
  const res = await apiAxios.post(
    '/accounting/payroll/next-previous-loan',
    request,
  );
  return res.data;
};
export const updateCommissionLoan = async (item) => {
  const res = await apiAxios.patch('/accounting/payroll', item);
  return res.data;
};
export const deleteCommissionLoan = async (item) => {
  // remove this as we are not removing loans
  const res = await apiAxios.delete('/accounting/payroll', {
    data: item,
  });
  return res.data;
};
export const deleteLoanAdjustment = async (item) => {
  const res = await apiAxios.delete('/accounting/payroll/adjustment', {
    data: item,
  });
  return res.data;
};
export const deleteLoanPayment = async (item) => {
  const res = await apiAxios.delete('/accounting/payroll/payment', {
    data: item,
  });
  return res.data;
};
export const deleteLoanAssistant = async (item) => {
  const res = await apiAxios.delete('/accounting/payroll/assistant', {
    data: item,
  });
  return res.data;
};

export const getMasterDatesAsync = async () => {
  const res = await apiAxios.get('/accounting/payroll/master-dates');
  return res.data;
};
export const updateMasterDatesAsync = async (item) => {
  const res = await apiAxios.patch('/accounting/payroll/master-dates', item);
  return res.data;
};

export const resetPayroll = async () => {
  // temporary delete later
  const res = await apiAxios.get('/accounting/payroll/reset-current-payroll');
  return res.data;
};

export const clearPayrollFinalizedDates = async () => {
  // temporary delete later
  const res = await apiAxios.get('/accounting/payroll/temporary-date-clear');
  return res.data;
};

export const addNewLoansToPayroll = async (data) => {
  const res = await apiAxios.post('/accounting/payroll/add-new-loans/' + data);
  return res.data;
};
export const removeLoanFromPayroll = async (id) => {
  const res = await apiAxios.patch(
    '/accounting/payroll/remove-payroll-loan/' + id,
  );
  return res.data;
};

export const getCurrentBudgetList = async (selectedLo) => {
  const res = await apiAxios.get(
    '/accounting/payroll/current-budget/' + selectedLo,
  );
  return res.data;
};
export const insertCurrentBudgetList = async (item) => {
  const res = await apiAxios.post('/accounting/payroll/current-budget', item);
  return res.data;
};
export const updateCurrentBudgetList = async (items) => {
  const res = await apiAxios.patch('/accounting/payroll/current-budget', items);
  return res.data;
};
export const deleteCurrentBudgetList = async (item) => {
  const res = await apiAxios.delete('/accounting/payroll/current-budget', {
    data: item,
  });
  return res.data;
};
export const getCurrentAdjustmentList = async (selectedLo) => {
  const res = await apiAxios.get(
    '/accounting/payroll/current-adjustment/' + selectedLo,
  );
  return res.data;
};
export const updateCurrentAdjustmentList = async (items) => {
  const res = await apiAxios.patch(
    '/accounting/payroll/current-adjustment',
    items,
  );
  return res.data;
};
export const insertCurrentAdjustmentList = async (item) => {
  const res = await apiAxios.post(
    '/accounting/payroll/current-adjustment',
    item,
  );
  return res.data;
};
export const deleteCurrentAdjustmentList = async (item) => {
  const res = await apiAxios.delete('/accounting/payroll/current-adjustment', {
    data: item,
  });
  return res.data;
};
export const payrollData = async (loId) => {
  try {
    const res = await apiAxios({
      method: 'get',
      url: '/accounting/reports/payroll-data?loid=' + loId,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const payrollEmail = async (loId, emailText) => {
  try {
    const res = await apiAxios({
      method: 'get',
      url:
        '/accounting/reports/payroll-email?loid=' +
        loId +
        (loId === 0 ? '&splitFullReport=true' : '') +
        '&emailText=' +
        emailText,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const priorPayrollEmail = async (masterDates, loId) => {
  try {
    const res = await apiAxios({
      method: 'post',
      url:
        '/accounting/reports/prior-payroll-email?loId=' +
        loId +
        (loId === 0 ? '&splitFullReport=true' : ''),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: masterDates,
    });
    return res.data;
  } catch (err) {
    return err;
  }
};
export const getPayrollReport = (loId, viewOnly) => async () => {
  try {
    const res = await apiAxios({
      method: 'get',
      url:
        '/accounting/reports/payroll?loid=' +
        loId +
        (loId === 0 ? '&splitFullReport=true' : ''),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });
    if (viewOnly) {
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);

      // Open the PDF in a new window
      window.open(pdfUrl, '_blank');
    } else {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const priorPayrollReport = (masterDates, loId, viewOnly) => async () => {
  try {
    const res = await apiAxios({
      method: 'post',
      url:
        '/accounting/reports/prior-payroll?loId=' +
        loId +
        (loId === 0 ? '&splitFullReport=true' : ''),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      data: masterDates,
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    if (viewOnly) {
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);

      // Open the PDF in a new window
      window.open(pdfUrl, '_blank');
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Prior-Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const downloadAdpExcel = (value) => async () => {
  try {
    const res = await apiAxios({
      method: 'post',
      url: '/accounting/reports/adp-export?includeSemiMonthly=' + value,
      headers: {
        'Content-Type': 'application/json',
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    link.href = url;
    link.setAttribute('download', 'AdpExport.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res;
  } catch (err) {
    return err;
  }
};

export const getPayrollData = async () => {
  const res = await apiAxios.get('/accounting/payroll/get-payroll-data');
  return res.data;
};

export const getPreviousPayrollDates = async () => {
  const res = await apiAxios.get('/accounting/payroll/previous-payroll-dates');
  return res.data;
};

export const getCommissionTotalAsync = async (selectedLo) => {
  const res = await apiAxios.get(
    '/accounting/payroll/total-commission/' + selectedLo,
  );
  return res.data;
};

export const getPayrollHistoryFolders = async () => {
  const res = await apiAxios.get('/accounting/files/folders/');
  return res.data;
};

export const getPayrollHistoryFiles = async (folderName) => {
  const res = await apiAxios.get(`/accounting/files/files/${folderName}`);
  return res.data;
};
export const downloadPayrollHistoryFile =
  (folderName, fileName) => async () => {
    const res = await apiAxios.get(
      `/accounting/files/download-file/${folderName}/${fileName}`,
    );

    const el = document.createElement('a');
    el.href = res.data.uri;
    el.setAttribute('download', res.data.name);
    document.body.append(el);
    el.click();
    el.remove();
  };

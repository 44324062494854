import {
  BorrowerType,
  CreateLoanParameters,
  CreateLoanQueryExtended,
  EncompassApplication,
  EncompassCustomField,
  EncompassEmployment,
  EncompassGiftGrant,
  EncompassLoan,
  EncompassLoanPatch,
  EncompassOtherAsset,
  EncompassOtherIncomeSource,
  EncompassOtherLiability,
  EncompassReoProperty,
  EncompassResidence,
  EncompassURLAAlternateName,
  EncompassVod,
  EncompassVol,
} from 'src/types';
import { apiAxios, mergeDeep, mergeDeepWith } from 'src/util';
import { WORKSTATE } from './types';

export const getLoanFieldsWithFieldReader = async (loanGuid, fields) => {
  const res = await apiAxios.post(`loans/${loanGuid}/fieldReader`, fields);
  return res.data;
};

export const saveLoanFields = async (loanGuid, fields) => {
  return await apiAxios.post(`loans/${loanGuid}/fieldWriter`, fields);
};

export const getLoanDuplicateData = async (loanGuid) => {
  return await apiAxios.get(`loans/${loanGuid}/copy`);
};

export const createLoanDuplicate = async (loanGuid, data) => {
  return await apiAxios.post(`loans/${loanGuid}/copy`, data);
};

export const getLoanApplicationsNoStore = async (
  loanGuid: string,
): Promise<EncompassApplication[]> => {
  const res = await apiAxios.get(`loans/${loanGuid}/applications`);
  return res.data;
};

export const lockLoanFields =
  (loanGuid, contractPaths) => async (dispatch, getState) => {
    await apiAxios.post(`/loans/${loanGuid}/fieldLocks`, contractPaths, {
      params: {
        action: 'add',
      },
    });
    const loan = getState().loans[loanGuid];
    if (loan) {
      dispatch({
        type: 'update_loan',
        data: {
          id: loanGuid,
          ...loan,
          fieldLockData: [
            // @ts-ignore
            ...new Set([
              ...(typeof loan?.fieldLockData?.[Symbol.iterator] === 'function'
                ? loan.fieldLockData
                : []),
              ...contractPaths,
            ]),
          ],
        },
      });
    }
  };

export const unlockLoanFields =
  (loanGuid, contractPaths) => async (dispatch, getState) => {
    await apiAxios.post(`/loans/${loanGuid}/fieldLocks`, contractPaths, {
      params: {
        action: 'remove',
      },
    });
    const loan = getState().loans[loanGuid];
    if (loan) {
      dispatch({
        type: 'update_loan',
        data: {
          ...loan,
          fieldLockData: loan?.fieldLockData?.filter(
            (f) => !contractPaths.includes(f),
          ),
        },
      });
    }
  };

export const getLoan = (loanGuid) => async (dispatch) => {
  const res = await apiAxios.get(`loans/${loanGuid}`);

  dispatch({
    type: 'set_loan',
    data: mapToCustomLoanFormat(res.data),
  });
};

/* const defaultLoanDataDeepMerger = (originalLoan, partialLoan) => {
  const customizer = (originalValue, newValue) => {
    // recursively merge until meet no Objects or Arrays
    if (isObject(newValue)) {
      return mergeWith(originalValue, newValue, customizer);
    } else if (Array.isArray(originalValue) && Array.isArray(newValue)) {
      // eslint-disable-next-line array-callback-return
      return originalValue.map((originalItem) => {
        const matchedItem = newValue.find(
          (newItem) => newItem.id === originalItem.id,
        );
        if (Array.isArray(matchedItem) || isObject(matchedItem)) {
          return mergeWith(originalItem, matchedItem, customizer);
        }
      });
    }
  };
  try {
    return mergeWith(originalLoan, partialLoan, customizer);
  } catch (e) {
    console.log(
      'store.loans merge failed: ',
      e,
      '\n',
      'Performing shallow merge.',
    );
    return { ...originalLoan, ...partialLoan };
  }
}; */

export const mapToCustomLoanFormat = (
  loan: EncompassLoanPatch,
): EncompassLoan => {
  if (Array.isArray(loan.customFields)) {
    // @ts-ignore
    loan.customFields = loan.customFields.reduce(
      (a: Record<string, string>, x: EncompassCustomField) => ({
        ...a,
        [x.fieldName]: x.value,
      }),
      {},
    );
  }
  return loan as EncompassLoan;
};

type MergerFunc = (
  originalLoan: EncompassLoan,
  partialLoan: EncompassLoan,
) => EncompassLoan;

export const startLoanWork = (
  loanGuid: string,
  data: object | null = null,
) => ({
  type: 'loan_start_work',
  data: { loanGuid, task: WORKSTATE.GENERIC_TASK, error: null, ...data },
});

export const stopLoanWork = (loanGuid: string, data: object | null = null) => ({
  type: 'loan_stop_work',
  data: { loanGuid, task: WORKSTATE.GENERIC_TASK, error: null, ...data },
});

export const createNewLoan =
  (createParams: CreateLoanParameters, createQuery: CreateLoanQueryExtended) =>
    async () => {
      const patch: Record<string, unknown> = {};
      if (createParams?.loan?.contacts) {
        patch.contacts = createParams.loan.contacts;
        delete createParams.loan.contacts;
      }
      if (createParams?.loan?.property) {
        patch.property = createParams.loan.property;
        delete createParams.loan.property;
      }

      let res = await apiAxios.post('loans', createParams, {
        params: createQuery,
      });

      if (Object.keys(patch).length) {
        res = await apiAxios.patch(`loans/${res.data.id}`, patch, {
          params: createQuery,
        });
      }

      return res;
    };

export const deleteLoan = (loanGuid: string) => async (dispatch) => {
  await apiAxios.delete(`loans/${loanGuid}`);
  dispatch({
    type: 'remove_loan',
    data: { id: loanGuid },
  });
  dispatch({
    type: 'remove_loan_from_pipeline',
    payload: { id: loanGuid },
  });
};

export const updateLoan =
  (
    loanGuid: string,
    loanPatch: EncompassLoanPatch,
    dataMerger: MergerFunc = mergeDeep,
  ) =>
    async (dispatch, getState) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      const lockId = getState().locks[loanGuid]?.id;
      const currentLoan = getState().loans[loanGuid];
      try {
        const unfrozenLoanCopy = JSON.parse(JSON.stringify(currentLoan));
        const beforeUpdateLoan = dataMerger(
          unfrozenLoanCopy,
          mapToCustomLoanFormat(loanPatch),
        );

        // customfieldsmapped contains arrays without keys, so mergeDeep will incorrectly append everything instead of replacing it
        if (loanPatch.customFieldsMapped) {
          beforeUpdateLoan.customFieldsMapped = mergeDeepWith(
            beforeUpdateLoan.customFieldsMapped,
            loanPatch.customFieldsMapped,
            (_, src) => src,
          );
        }

        dispatch({
          type: 'set_loan',
          data: beforeUpdateLoan,
        });

        const res = await apiAxios.patch(`loans/${loanGuid}`, loanPatch, {
          params: {
            lockId,
          },
        });

        const newData = dataMerger(
          beforeUpdateLoan,
          mapToCustomLoanFormat(res.data),
        );

        // customfieldsmapped contains arrays without keys, so mergeDeep will incorrectly append everything instead of replacing it
        if (res.data.customFieldsMapped) {
          newData.customFieldsMapped = res.data.customFieldsMapped;
        }

        dispatch({
          type: 'set_loan',
          data: newData,
        });
        return newData;
      } catch (e) {
        dispatch({
          type: 'set_loan',
          data: JSON.parse(JSON.stringify(currentLoan)),
        });
        error = e.response?.data?.messages?.[0];
        return e;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const exitLoan = (loanGuid: string) => async (dispatch) => {
  dispatch({
    type: 'remove_loan',
    data: { id: loanGuid },
  });
};

export const fetchLoanSummary = (loanGuid: string) => async (dispatch) => {
  const res = await apiAxios.get(`loans/${loanGuid}/summary`);
  return res.data;
};

export const createLoanApplication =
  (loanGuid: string, application: EncompassApplication) => async (dispatch) => {
    const res = await apiAxios.post(
      `loans/${loanGuid}/applications`,
      application,
    );
    dispatch({
      type: 'add_loan_application',
      data: {
        loanGuid,
        application: res.data,
      },
    });
    return res.data;
  };

export const removeLoanApplication =
  (loanGuid: string, applicationId: string) => async (dispatch) => {
    await apiAxios.delete(`loans/${loanGuid}/applications/${applicationId}`);
    dispatch({
      type: 'remove_loan_application',
      data: {
        loanGuid,
        applicationId,
      },
    });
  };

export const createAsset =
  (loanGuid: string, applicationId: string, data?: EncompassVod) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/assets`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'vods',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateAsset =
  (
    loanGuid: string,
    applicationId: string,
    vodId: string,
    data?: EncompassVod,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/assets/${vodId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'vods',
            entityId: vodId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteAsset =
  (loanGuid: string, applicationId: string, vodId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/assets/${vodId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'vods',
            entityId: vodId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createOtherAsset =
  (loanGuid: string, applicationId: string, data?: EncompassOtherAsset) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/otherAssets`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherAssets',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateOtherAsset =
  (
    loanGuid: string,
    applicationId: string,
    otherAssetId: string,
    data?: EncompassOtherAsset,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/otherAssets/${otherAssetId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherAssets',
            entityId: otherAssetId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteOtherAsset =
  (loanGuid: string, applicationId: string, otherAssetId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/otherAssets/${otherAssetId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherAssets',
            entityId: otherAssetId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createOtherIncomeSource =
  (
    loanGuid: string,
    applicationId: string,
    data?: EncompassOtherIncomeSource,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/otherIncomeSources`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherIncomeSources',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateOtherIncomeSource =
  (
    loanGuid: string,
    applicationId: string,
    otherIncomeSourceId: string,
    data?: EncompassOtherIncomeSource,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/otherIncomeSources/${otherIncomeSourceId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherIncomeSources',
            entityId: otherIncomeSourceId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteOtherIncomeSource =
  (loanGuid: string, applicationId: string, otherIncomeSourceId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/otherIncomeSources/${otherIncomeSourceId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherIncomeSources',
            entityId: otherIncomeSourceId,
          },
        });
        return res.data;
      } catch (err) {
        console.warn(err);
        return err;
      }
    };

export const createEmployment =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    data?: EncompassEmployment,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/employments`,
        data,
        );
        dispatch({
          type: 'add_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'employment',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateEmployment =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    employmentId: string,
    data?: EncompassEmployment,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/employments/${employmentId}`,
        data,
        );
        dispatch({
          type: 'update_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'employment',
            entityId: employmentId,
            item: res.data,
          },
        });
        //update calculated fields in redux state
        await dispatch(updateDtiCalculatedFields(loanGuid, applicationId));
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteEmployment =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    employmentId: string,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/employments/${employmentId}`,
        );
        dispatch({
          type: 'delete_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'employment',
            entityId: employmentId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createLiability =
  (loanGuid: string, applicationId: string, data?: EncompassVol) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/liabilities`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'vols',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateLiability =
  (
    loanGuid: string,
    applicationId: string,
    volId: string,
    data?: EncompassVol,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/liabilities/${volId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'vols',
            entityId: volId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteLiability =
  (loanGuid: string, applicationId: string, volId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/liabilities/${volId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'vols',
            entityId: volId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createOtherLiability =
  (loanGuid: string, applicationId: string, data?: EncompassOtherLiability) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/otherLiabilities`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherLiabilities',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateOtherLiability =
  (
    loanGuid: string,
    applicationId: string,
    otherLiabilityId: string,
    data?: EncompassOtherLiability,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/otherLiabilities/${otherLiabilityId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherLiabilities',
            entityId: otherLiabilityId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteOtherLiability =
  (loanGuid: string, applicationId: string, otherLiabilityId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/otherLiabilities/${otherLiabilityId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'otherLiabilities',
            entityId: otherLiabilityId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createGiftGrant =
  (loanGuid: string, applicationId: string, data?: EncompassGiftGrant) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/giftsGrants`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'giftsGrants',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateGiftGrant =
  (
    loanGuid: string,
    applicationId: string,
    giftGrantId: string,
    data?: EncompassGiftGrant,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/giftsGrants/${giftGrantId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'giftsGrants',
            entityId: giftGrantId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteGiftGrant =
  (loanGuid: string, applicationId: string, giftGrantId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/giftsGrants/${giftGrantId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'giftsGrants',
            entityId: giftGrantId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createAlternateName =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    data?: EncompassURLAAlternateName,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/alternateNames`,
        data,
        );
        dispatch({
          type: 'add_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'urlaAlternateNames',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateAlternateName =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    alternateNameId: string,
    data?: EncompassURLAAlternateName,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/alternateNames/${alternateNameId}`,
        data,
        );
        dispatch({
          type: 'update_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'urlaAlternateNames',
            entityId: alternateNameId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteAlternateName =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    alternateNameId: string,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/alternateNames/${alternateNameId}`,
        );
        dispatch({
          type: 'delete_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'urlaAlternateNames',
            entityId: alternateNameId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createReoProperty =
  (loanGuid: string, applicationId: string, data?: EncompassReoProperty) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/properties`,
        data,
        );
        dispatch({
          type: 'add_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'reoProperties',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateReoProperty =
  (
    loanGuid: string,
    applicationId: string,
    propertyId: string,
    data?: EncompassReoProperty,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/properties/${propertyId}`,
        data,
        );
        dispatch({
          type: 'update_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'reoProperties',
            entityId: propertyId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteReoProperty =
  (loanGuid: string, applicationId: string, propertyId: string) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/properties/${propertyId}`,
        );
        dispatch({
          type: 'delete_loan_application_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            entityKey: 'reoProperties',
            entityId: propertyId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const createResidence =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    data?: EncompassResidence,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.post(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/residences`,
        data,
        );
        dispatch({
          type: 'add_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'residences',
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateResidence =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    residenceId: string,
    data?: EncompassResidence,
  ) =>
    async (dispatch) => {
      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        const res = await apiAxios.patch(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/residences/${residenceId}`,
        data,
        );
        dispatch({
          type: 'update_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'residences',
            entityId: residenceId,
            item: res.data,
          },
        });
        return res.data;
      } catch (err) {
        error = err.response?.data?.messages?.[0];
        return err;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const deleteResidence =
  (
    loanGuid: string,
    applicationId: string,
    borrowerType: BorrowerType,
    residenceId: string,
  ) =>
    async (dispatch) => {
      try {
        const res = await apiAxios.delete(
        `loans/${loanGuid}/applications/${applicationId}/${borrowerType.toLowerCase()}/residences/${residenceId}`,
        );
        dispatch({
          type: 'delete_loan_borrower_entitycollection_item',
          data: {
            loanGuid,
            applicationId,
            borrowerType,
            entityKey: 'residences',
            entityId: residenceId,
          },
        });
        return res.data;
      } catch (err) {
        return err;
      }
    };

export const updateDtiCalculatedFields =
  (loanGuid: string, applicationId: string) => async (dispatch, getState) => {
    // do we need this complicated merge pattern? probably
    const res = await getLoanFieldsWithFieldReader(loanGuid, ['740', '742']);
    const currentLoan = getState().loans[loanGuid];
    const data = {
      id: loanGuid,
      applications: [
        {
          id: applicationId,
          topRatioPercent: res?.[740],
          bottomRatioPercent: res?.[742],
        },
      ],
    };
    const unfrozenLoanCopy = JSON.parse(JSON.stringify(currentLoan));
    const newLoan = mergeDeep(unfrozenLoanCopy, mapToCustomLoanFormat(data));
    dispatch({
      type: 'set_loan',
      data: mapToCustomLoanFormat(newLoan),
    });
  };

export const updateCustomFields =
  (loanGuid: string, customFields: EncompassCustomField[]) =>
    async (dispatch) => {
    // avoid using this if you can for now
    // cannot seem to update the old predefined Encompass customfields through FMM EncompassHttp
    // also shouldn't add to loan.customFields directly as we map out the fields on our end for reading purposes

      dispatch(startLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN }));
      let error: string | null = null;
      try {
        await apiAxios.patch(`loans/${loanGuid}/customFields`, customFields);
        dispatch({
          type: 'update_loan_custom_fields',
          data: mapToCustomLoanFormat({
            id: loanGuid,
            applications: [],
            customFields: customFields,
          }),
        });
      } catch (e) {
        error = e.response?.data?.messages?.[0];
        return e;
      } finally {
        dispatch(stopLoanWork(loanGuid, { task: WORKSTATE.UPDATE_LOAN, error }));
      }
    };

export const getCustomFieldOptions = async (fieldId) => {
  return (await apiAxios.get(`loans/custom-field-options/${fieldId}`)).data;
};

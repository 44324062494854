import { apiAxios } from 'src/util';

export const getLodestarCounties = async (state) => {
  const res = await apiAxios.get(`/lodestar/counties/state/${state}`);
  return res.data;
};

export const getLodestarTownships = async (state, county) => {
  const res = await apiAxios.get(
    `/lodestar/townships/state/${state}/county/${county}`,
  );
  return res.data;
};

export const getLodestarEndorsements = async (state, county, purpose) => {
  const res = await apiAxios.get(
    `/lodestar/endorsements/state/${state}/county/${county}/purpose/${purpose}`,
  );
  return res.data;
};

export const getLodestarAppraisalModifiers = async (state, county, purpose) => {
  const res = await apiAxios.get(
    `/lodestar/apprmod/state/${state}/county/${county}/purpose/${purpose}`,
  );
  return res.data;
};

export const getLodestarQuestions = async (state, purpose) => {
  const res = await apiAxios.get(
    `/lodestar/questions/state/${state}/purpose/${purpose}`,
  );
  return res.data;
};

export const getLodestarRequest = async (request) => {
  try {
    const res = await apiAxios.post('/lodestar/loan-fee-request', request);
    return res.data;
  } catch (e) {
    return e;
  }
};

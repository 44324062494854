import { apiAxios } from 'src/util';

export const fetchReportingDashboards = () => async (dispatch) => {
  const res = await apiAxios.get('/reporting/dashboards');
  dispatch({
    type: 'set_reporting_dashboards',
    data: res.data,
  });
};

export const createReportingDashboard = (name) => async (dispatch) => {
  const res = await apiAxios.post('/reporting/dashboards', {
    name,
  });
  dispatch({
    type: 'add_reporting_dashboard',
    data: res.data,
  });
  return res.data;
};

export const updateReportingDashboard = (id, body) => async (dispatch) => {
  const res = await apiAxios.patch(`/reporting/dashboards/${id}`, body);
  dispatch({
    type: 'update_reporting_dashboard',
    data: res.data,
  });
};

export const deleteReportingDashboard = (id) => async (dispatch) => {
  await apiAxios.delete(`/reporting/dashboards/${id}`);

  dispatch({
    type: 'remove_reporting_dashboard',
    data: id,
  });
};

export const fetchReportingDashboardWidgets =
  (dashboardId) => async (dispatch) => {
    const res = await apiAxios.get(`/reporting/dashboards/${dashboardId}`);

    dispatch({
      type: 'update_reporting_dashboard',
      data: res.data,
    });

    return res.data;
  };

export const addReportingDashboardWidget =
  (dashboardId, postBody) => async (dispatch, getState) => {
    const res = await apiAxios.post(
      `/reporting/dashboards/${dashboardId}/widgets`,
      postBody,
    );
    const state = getState();
    const loanOfficer = state.loanOfficer;

    const data = res.data;

    if (postBody.filterId) {
      const filters = state.reportingDashboardTemplateFilters;
      const filter = filters.find((f) => f.id === postBody.filterId);
      dispatch({
        type: 'add_reporting_dashboard_template',
        data: {
          filterId: postBody.filterId,
          id: data.templateId,
          name: filter.name,
          ownerId: loanOfficer.id,
          isShared: false,
          pipelineFilter: filter?.pipelineFilter,
          visibleTo: filter?.visibleTo,
          displayConfiguration: data.displayConfiguration,
        },
      });
    }

    dispatch({
      type: 'add_reporting_dashboard_widget',
      data: {
        dashboardId,
        widget: data,
      },
    });
  };

export const updateReportingDashboardWidgetPositions =
  (dashboardId, positions) => async (dispatch) => {
    const res = await apiAxios.patch(
      `/reporting/dashboards/${dashboardId}/widgets/move`,
      positions,
    );

    dispatch({
      type: 'update_reporting_dashboard_widget_positions',
      data: {
        dashboardId,
        widgets: res.data,
      },
    });
  };

export const updateReportingDashboardWidget =
  (dashboardId, widgetId, data) => async (dispatch) => {
    const res = await apiAxios.patch(
      `/reporting/dashboards/${dashboardId}/widgets/${widgetId}`,
      data,
    );

    dispatch({
      type: 'add_or_update_reporting_dashboard_widget',
      data: {
        dashboardId,
        widget: res.data,
      },
    });
  };

export const deleteReportingDashboardWidget =
  (dashboardId, widgetId) => async (dispatch) => {
    await apiAxios.delete(
      `/reporting/dashboards/${dashboardId}/widgets/${widgetId}`,
    );

    dispatch({
      type: 'remove_reporting_dashboard_widget',
      data: {
        dashboardId,
        widgetId,
      },
    });
  };

export const getProcessDatesDataForMers = async () => {
  const res = await apiAxios.get('/mers/dates');
  return res.data;
};

export const getReportDataForMers = async (date) => {
  const res = await apiAxios.get(`/mers/report/${date}`);
  return res.data;
};

export const IgnoreDiscrepancies = async (data) => {
  const res = await apiAxios.post('mers/ignored', data);
  return res.data;
};

export const getMersTransfersData = async (data) => {
  const res = await apiAxios.post('mers/transfers', data);
  return res.data;
};

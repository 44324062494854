import { CinchLoanTypeFull } from 'src/types';
import { CinchLoanType } from 'src/types/CinchLoanType';

export const cacheBust = function cacheBust () {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const length = 8;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return '?' + result;
};

export const enforceHandholdCapitalizeName =
  function enforceHandholdCapitalizeName (name) {
    // returns the string 'name' with the first letter capitalized.
    // really?
    // come on man
    // you couldn't just hold shift for one split second?
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

export const getLoanType = (selectedLoanOrCustomFields) => {
  switch (true) {
    case selectedLoanOrCustomFields?.Fields_CX_TARGETLOANPROGRAM ===
      'MultiFamily - Commercial' ||
      selectedLoanOrCustomFields?.['CX.TARGETLOANPROGRAM'] ===
        'MultiFamily - Commercial' ||
      selectedLoanOrCustomFields?.Fields_CX_TARGETLOANPROGRAM ===
        'SB Multi family' ||
      selectedLoanOrCustomFields?.['CX.TARGETLOANPROGRAM'] ===
        'SB Multi family' ||
      selectedLoanOrCustomFields?.Loan_LoanNumber?.startsWith('COM') ||
      selectedLoanOrCustomFields?.loanNumber?.startsWith('COM') ||
      selectedLoanOrCustomFields?.['loanNumber']?.startsWith('COM') ||
      selectedLoanOrCustomFields?.['Loan_LoanNumber']?.startsWith('COM'):
      return CinchLoanType.MultiFamilyCommercial;
    case selectedLoanOrCustomFields?.Fields_CX_TARGETLOANPROGRAM ===
      'FM SB Multifamily':
      return CinchLoanType.FMSBMultifamily;
    case (selectedLoanOrCustomFields?.Fields_CX_COMMERCIAL === 'Yes' ||
      selectedLoanOrCustomFields?.['CX.COMMERCIAL'] === 'Yes') &&
      selectedLoanOrCustomFields?.Fields_CX_FIXANDFLIP !== 'Yes' &&
      selectedLoanOrCustomFields?.['CX.FIXANDFLIP'] !== 'Yes':
      return CinchLoanType.Commercial;
    case selectedLoanOrCustomFields?.Fields_CX_FIXANDFLIP === 'Yes' ||
      selectedLoanOrCustomFields?.['CX.FIXANDFLIP'] === 'Yes':
      return CinchLoanType.FixAndFlip;
    default:
      return CinchLoanType.Residential;
  }
};

export const getCinchLoanTypeFull = (loan) => {
  switch (true) {
    case loan.customFieldsMapped?.targetLoanProgram?.program ===
      'MultiFamily - Commercial' ||
      loan.customFieldsMapped?.targetLoanProgram?.program ===
        'SB Multi family' ||
      loan?.loanNumber?.startsWith('COM'):
      return CinchLoanTypeFull.MultiFamilyCommercial;
    case loan.customFieldsMapped?.targetLoanProgram?.program ===
      'FM SB Multifamily':
      return CinchLoanTypeFull.FMSBMultifamily;
    case loan.customFieldsMapped?.legacyLoanTypes.commercial === 'Yes':
      return CinchLoanTypeFull.Commercial;
    case loan.customFieldsMapped?.legacyLoanTypes.fixAndFlip === 'Yes':
      return CinchLoanTypeFull.FixAndFlip;
    default:
      return CinchLoanTypeFull.Residential;
  }
};

export const isObjectEmpty = (obj) => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    if (Object.keys(obj)?.length === 0) {
      return true;
    }
    return false;
  }
  return null;
};

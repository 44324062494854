import { deepFreeze } from 'src/util';

const initialState = Object.freeze({});

/**
 * @param {{ [loanGuid: string]: { documents: any[], attachments: any[] } }} state
 * @param {{ type: string, data: { loanGuid: string, attachments: any[], documents: any[], document: any, attachment: any } }} action
 */
const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case 'set_multifamily_filesystem': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          documents: data.documents,
          attachments: data.attachments,
        },
      });
    }
    case 'add_multifamily_document': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          documents: [
            ...(state[data.loanGuid].documents ?? []),
            { name: data.document, count: 0 },
          ],
        },
      });
    }
    case 'add_multifamily_attachment': {
      const documents = state[data.loanGuid].documents.map((d) =>
        d.name === data.document
          ? {
            ...d,
            count: state[data.loanGuid].attachments.some(
              (att) =>
                att.parentFolderName === d.name &&
                  att.name === data.attachment.name,
            )
              ? d.count
              : (d.count || 0) + 1,
          }
          : d,
      );
      // Update the attachments array: replace existing attachment or add new one
      const attachments = state[data.loanGuid].attachments.map((att) =>
        att.name === data.attachment.name &&
        att.parentFolderName === data.attachment.parentFolderName
          ? { ...att, ...data.attachment }
          : att,
      );

      if (
        !attachments.some(
          (att) =>
            att.name === data.attachment.name &&
            att.parentFolderName === data.attachment.parentFolderName,
        )
      ) {
        attachments.push(data.attachment);
      }
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments,
          documents, // Updated documents array
        },
      });
    }
    case 'delete_multifamily_attachment': {
      const documents = state[data.loanGuid].documents.map((d) =>
        d.name === data.document
          ? { ...d, count: Math.max((d.count || 0) - 1, 0) }
          : d,
      );
      const attachments = state[data.loanGuid].attachments.filter(
        (att) =>
          !(
            att.name === data.attachment.name &&
            att.parentFolderName === data.attachment.parentFolderName
          ),
      );
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments,
          documents,
        },
      });
    }
    default:
      return state;
  }
};

export default reducer;

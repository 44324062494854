import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { PhoneInput } from '@fmm/react-encompass-inputs';
import { useAutosavingFormContext } from 'src/components/ConnectedInputs/AutosavingForm';
import { PHONE_REGEX, PHONE_REGEX_WITHOUT_EXTENSION } from 'src/util';

export const ConnectedPhoneNumberInput = React.forwardRef(
  ({ name, rules, required, id, noExtension = false, ...props }, ref) => {
    const { id: contextId } = useAutosavingFormContext();
    return (
      <Controller
        name={name}
        rules={{
          required,
          pattern: {
            // will need to change in PhoneInput to properly mask
            value: noExtension ? PHONE_REGEX_WITHOUT_EXTENSION : PHONE_REGEX,
            message: 'Please enter a valid phone number',
          },
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <PhoneInput
            id={id ?? contextId ? `${contextId}.${name}` : name}
            variant='outlined'
            size='small'
            fullWidth
            {...props}
            name={name}
            value={value || ''}
            onChange={(e) => {
              const value = e.target.value;
              onChange(value);
            }}
            onBlur={onBlur}
            inputProps={props.inputProps}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            required={Boolean(required || rules?.required)}
          />
        )}
      />
    );
  },
);

ConnectedPhoneNumberInput.displayName = 'ConnectedPhoneNumberInput';

ConnectedPhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  capitalize: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  noExtension: PropTypes.bool,
};
